import { PageProps } from 'gatsby'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import ContactContainer from '../../components/ContactContainer'
import ContactFormContainer from '../../components/ContactFormContainer'
import Form from '../../components/Form'
import { ContactFormData } from '../../models/ContactFormData'
import { yupResolver } from '@hookform/resolvers/yup'
import contactFormSchema from '../../validations/contactFormSchema'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import { LayoutProps } from '../../components/Layout.context'
import { useModal } from '../../components/ReactModal'
import fetchSaveApi from '../../utils/fetchSaveApi'
import { ContactRequest } from '../../models/ContactRequest'
import useFormError from '../../hooks/useFormError'
import { Helmet } from 'react-helmet'
import AboutContent from '../../components/AboutContent'
import FranchiseGrid from '../../components/FranchiseGrid'
import BusinessModelGrid from '../../components/BusinessModelGrid'
import identity from '../../data/identity'
import profiles from '../../data/profiles'
import businessModel from '../../data/businessModel'
import theme from '../../theme/theme'
import partners from '../../data/partners'
import PartnersGridList from '../../components/PartnersGridList'
import AboutBanner from '../../components/AboutBanner'

const ContactPage: React.FC<PageProps> = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ContactFormData>({
    resolver: yupResolver(contactFormSchema),
    shouldUnregister: true,
  })

  const { isOpen, requestOpen, onRequestClose } = useModal()
  const [formError, setFormError] = useFormError(isSubmitting)
  const formRef =
    useRef<null | HTMLDivElement>() as React.MutableRefObject<null | HTMLDivElement>

  useEffect(() => {
    setValue('type', 'franchise')
  }, [])

  const handleClickContact = () => {
    if (!formRef) return
    formRef.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const onSubmit = handleSubmit(async (data) => {
    const req: ContactRequest = {
      lastname: data.lastname,
      firstname: data.firstname,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      message: data.message,
    }

    const formData = new FormData()
    for (const [key, value] of Object.entries(req)) {
      if (typeof value === 'boolean') {
        formData.append(key, value ? 'true' : 'false')
      } else if (value instanceof File) {
        formData.append(key, value, value.name)
      } else if (typeof value === 'string') {
        formData.append(key, value)
      }
    }

    const resp = await fetchSaveApi<string>('/website/v1/contact/franchise', {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': '', // used to remove default Content-Type
      },
    })

    if (!resp) {
      setFormError({
        type: 'required',
        message: "Une erreur est survenue lors de l'envoi du message",
      })
      return
    }

    requestOpen()
    return
  })

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Que vous soyez un client, un journaliste, un futur franchisé Save ou même les trois en même temps, vous pouvez nous laisser un petit message sur notre formulaire de contact."
        />
      </Helmet>

      <AboutBanner title="Devenir Franchisé Save" />

      <AboutContent>
        <AboutContent.Title color={theme.colors.secondary}>
          Rejoignez le leader de la réparation de proximité en France
        </AboutContent.Title>
        <AboutContent.Paragraph color={theme.colors.secondary}>
          Créé en 2006 sous le nom Point Service Mobiles, notre réseau s’est
          développé principalement en franchise et s’appuie sur des agréments
          constructeurs pour garantir le meilleur niveau de qualité possible.
        </AboutContent.Paragraph>
        <AboutContent.Paragraph color={theme.colors.secondary}>
          Avec près de 200 magasins en France, nous avons développé notre
          maillage territorial pour être au plus près de nos clients, et placer
          leur satisfaction au coeur de notre démarche.
        </AboutContent.Paragraph>
        <AboutContent.Paragraph color={theme.colors.secondary}>
          En 2022, le réseau Save a été récompensé par le magazine Capital
          <br />
          et Statista qui nous ont élu au palmarès de la catégorie <br />
          &laquo; Réparation de smartphones, ordinateurs, et produits techniques
          &raquo;.
        </AboutContent.Paragraph>
        <Button onClick={handleClickContact}>Nous contacter</Button>
      </AboutContent>

      <AboutContent colored noLimitWidth>
        <AboutContent.Title color="white">Identité</AboutContent.Title>
        <FranchiseGrid items={identity} />
      </AboutContent>

      <AboutContent noLimitWidth>
        <AboutContent.Title color={theme.colors.secondary}>
          Business Model
        </AboutContent.Title>
        <BusinessModelGrid items={businessModel} />
      </AboutContent>

      <AboutContent noLimitWidth>
        <AboutContent.Title color={theme.colors.secondary}>
          Principaux partenaires
        </AboutContent.Title>
        <PartnersGridList items={partners} />
      </AboutContent>

      <AboutContent colored noLimitWidth>
        <AboutContent.Title color="white">
          Profil et conditions d'entrée
        </AboutContent.Title>
        <AboutContent.Paragraph color="white">
          Nous recherchons des entrepreneurs passionnés par les technologies et
          l'économie circulaire ayant les compétences suivantes :<br />
          Management et animation d'équipes, expérience dans la gestion de
          points de vente
          <br />
          Forte connaissance du tissu économique local
          <br />
          Compétences commerciales permettant le développement du business BtoB
          local
        </AboutContent.Paragraph>
        <FranchiseGrid items={profiles} />
      </AboutContent>

      <AboutContent noLimitWidth>
        <AboutContent.Title color={theme.colors.secondary}>
          Notre accompagnement
        </AboutContent.Title>
        <AboutContent.Paragraph color={theme.colors.secondary}>
          Services et solutions permettant l'implantation du magasin et son
          agencement
        </AboutContent.Paragraph>
        <AboutContent.Paragraph color={theme.colors.secondary}>
          Une formation aux outils de gestion à la réparation et aux techniques
          de ventes
        </AboutContent.Paragraph>
        <AboutContent.Paragraph color={theme.colors.secondary}>
          L'accès à nos fournisseurs référents (pièces détachées, accessoires,
          smartphones)
        </AboutContent.Paragraph>
        <AboutContent.Paragraph color={theme.colors.secondary}>
          Un accompagnement et des outils personnalisés pour le développement
          BtoC et BtoB
        </AboutContent.Paragraph>
      </AboutContent>

      <ContactContainer ref={formRef}>
        <Form onSubmit={onSubmit}>
          <AboutContent.Title color={theme.colors.secondary}>
            Contactez-nous
          </AboutContent.Title>

          <ContactFormContainer>
            <Form.Input
              {...register('lastname')}
              error={errors.lastname}
              placeholder="Nom"
            />
            <Form.Input
              {...register('firstname')}
              error={errors.firstname}
              placeholder="Prénom"
            />
            <Form.Input
              {...register('email')}
              error={errors.email}
              placeholder="Adresse mail"
            />
            <Form.Input
              {...register('phone')}
              error={errors.phone}
              placeholder="Numéro"
            />
            <Form.Input
              {...register('subject')}
              error={errors.subject}
              placeholder="Objet"
            />
            <Form.Textarea
              {...register('message')}
              error={errors.message}
              placeholder="Message…"
              minRows={9}
            />
            <Form.Submit
              disabled={isSubmitting}
              size="large"
              error={formError}
            />
          </ContactFormContainer>
        </Form>
        <Modal
          showHero
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          message={
            <>
              Votre message nous <br />a bien été envoyé
            </>
          }
        >
          <Button.Link to="/">Retour à la page d’accueil</Button.Link>
        </Modal>
      </ContactContainer>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Devenir franchisé Save',
  breadcrumb: [{ label: 'Devenir franchisé Save' }],
  showHighlights: true,
}

export default Object.assign(ContactPage, {
  layoutProps,
})
